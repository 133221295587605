<style lang="less" scoped>
/deep/ .ant-form {
  > .ant-row {
    padding-left: 20px;
    /deep/ .ant-form-item-label {
      width: 120px;
      text-align: left;
    }
  }
  .ant-form-item-control{
  }
  .ant-form-item{
    margin-bottom: 0;
  }
  .ant-form-item-label{
 }
}
.support {
  /deep/ .ant-form-item-label {
    width: 200px !important;
    text-align: left;
  }
    .ant-row {
    padding-left: 40% !important;
    }
}
</style>
<template>
<div>
  <a-card :body-style="{ padding: '24px 32px' }" :bordered="false">
    <a-form :form="pageForm" @submit="pageSubmit">

      <a-row>
        <a-col :xl="10" :lg="24" :md="24" :sm="24">
          <a-form-item label="一口价报价表下载备注" :labelCol="{ lg: { span: 12 }, sm: { span: 12 } }">
            <a-textarea
              name="content1"
              :rows="8"
              v-decorator="['content1', pageFormRules.content1]"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :xl="10" :lg="24" :md="24" :sm="24">
          <a-form-item label="分段报价表下载备注" :labelCol="{ lg: { span: 12 }, sm: { span: 12 } }">
            <a-textarea
              name="content2"
              :rows="8"
              v-decorator="['content2', pageFormRules.content2]"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-form-item :wrapperCol="{ span: 18 }">
        <a-input name="id" type="hidden" style="display: none" v-decorator="['id', pageFormRules.id]" />
        <div style="text-align: left;width:100%;">
          <a-button type="primary" htmlType="submit">保存</a-button>
        </div>
      </a-form-item>
    </a-form>
  </a-card>
</div>
</template>

<script>
import { extTextInfo, extTextInsert, extTextUpdate } from '@/api/quotation'

const textType = 1;
const pageFormRules = {
  id: { initialValue: null },
  content1: { rules: [{ required: true, message: '一口价报价表下载备注' }] },
  content2: { rules: [{ required: true, message: '分段报价表下载备注' }] },
}

export default {
  name: 'BaseForm',
  data() {
    return {
      pageForm: this.$form.createForm(this),
      pageFormRules: pageFormRules,
    }
  },
  mounted() {
    this.pageLoad();
  },
  methods: {
    pageLoad() {
      let that = this
      extTextInfo({textType: textType})
        .then((res) => {
          if (0 == res.code) {
            const record = res.result
            if (typeof(record) != 'object') {
              return;
            }
            that.$nextTick(() => {
              that.pageForm.setFieldsValue({
                id: record.id,
                content1: record.content1,
                content2: record.content2,
              })
            })
          }
        })
        .catch((e) => {
          console.info(e)
          this.$message.error('数据加载失败，请稍后重试')
        })
    },
    pageSubmit(e) {
      e.preventDefault()
      let that = this
      this.pageForm.validateFields((err, values) => {
        if (!err) {
          if (values.id) {
            that.handleUpdate(values)
          } else {
            that.handleInsert(values)
          }
        }
      })
    },
    handleInsert(parameter) {
      let that = this
      parameter.textType = textType;
      extTextInsert(parameter)
        .then((res) => {
          if (0 == res.code) {
            this.$success({ title: '保存成功' })
            that.pageLoad()
          } else {
            this.$error({ title: '保存失败' })
          }
        })
        .catch((e) => {
          console.info(e)
          this.$error({ title: '保存失败，请稍后重试' })
        })
    },
    handleUpdate(parameter) {
      let that = this
      extTextUpdate(parameter)
        .then((res) => {
          if (0 == res.code) {
            this.$success({ title: '保存成功' })
            that.pageLoad()
          } else {
            this.$error({ title: '保存失败' })
          }
        })
        .catch((e) => {
          console.info(e)
          this.$error({ title: '保存失败，请稍后重试' })
        })
    },
  },
}
</script>
<style lang="less" scoped>
  .ant-form{
   /deep/ .ant-form-item-children{
      width: 500px;
      display: flex;
      .ant-input-number{
        border-radius: 5px 0 0 5px;
      }
      .percentage{
        border-radius:0 5px 5px 0;
        border-left: 0;
      }
    }
  }
  /deep/ .ant-form-item-label{
    width: 200px;
    text-align: left !important;
}
.ant-form-item{
  display: flex;
  align-items: center;
}
</style>